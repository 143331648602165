import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import PrimaryMainLoader from "./Components/Loaders/PrimaryMainLoader";
import ContactusButton from "./Components/ContactusButton/ContactusButton";
import FAQ from "./Pages/FAQ/FAQ";
import ApiCall from "./api/ApiCall";
import { currencyChange } from "./api/Endpoints";
import { analyticsInstance } from "./Components/GlobalFunction/BaseTracking";
import { analyticsTrackingValues } from "./utils/analyticsTrackingValues";

const Home = lazy(() => import("./Pages/Home/Home"));
const HotelHome = lazy(() => import("./Pages/Hotel/HotelHome/HotelHome"));
const HotelsList = lazy(() => import("./Pages/Hotel/HotelsList/HotelsList"));
const HotelDetails = lazy(() =>
  import("./Pages/Hotel/HotelDetails/HotelDetails")
);
const BookMyRoom = lazy(() => import("./Pages/Hotel/BookMyRoom/BookMyRoom"));
const HotelBookSuccessfully = lazy(() =>
  import("./Pages/Hotel/HotelBookSuccessfully/HotelBookSuccessfully")
);
const CabHome = lazy(() => import("./Pages/Cab/CabHome/CabHome"));
const CabsList = lazy(() => import("./Pages/Cab/CabsList/CabsList"));
const CabBooking = lazy(() => import("./Pages/Cab/CabBooking/CabBooking"));
const Navbar = lazy(() => import("./Components/Header/Navbar"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const Deals = lazy(() => import("./Pages/Deals/Deals"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const About = lazy(() => import("./Pages/About/About"));
const Flights = lazy(() => import("./Pages/Flights/Flights"));
const Checkout = lazy(() => import("./Pages/Checkout/Checkout"));
const BookSuccessfully = lazy(() =>
  import("./Pages/BookSuccessfully/BookSuccessfully")
);
const SingleBlog = lazy(() => import("./Pages/Blogs/SingleBlog/SingleBlog"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./Pages/TermsAndConditions/TermsAndConditions")
);
const Dashboard = lazy(() => import("./Pages/Profile/Dashboard"));

const App = () => {
  const [country, setCountry] = useState("");
  const [currentCurrency, setcurrentCurrency] = useState({
    code: "USD",
    symbol: "$",
  });
  const [ipAddress, setipAddress] = useState("")
  const [currentCurrencyValue, setcurrentCurrencyValue] = useState({
    symbol: "",
    value: 0,
  });

  analyticsInstance().initialize([
    {
      trackingId: analyticsTrackingValues?.trackingId,
      testMode: false,
    },
  ]);

  // ************BLOCK INSPECT**********
  // useEffect(() => {
  //   const disableRightClick = (event) => event.preventDefault();
  //   document.addEventListener("contextmenu", disableRightClick);

  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);

  // useEffect(() => {
  //   const disableKeys = (event) => {
  //     if (
  //       event.keyCode === 123 || // F12
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
  //       (event.ctrlKey && event.keyCode === 85) // Ctrl+U (view source)
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener("keydown", disableKeys);

  //   return () => {
  //     document.removeEventListener("keydown", disableKeys);
  //   };
  // }, []);
  // useEffect(() => {
  //   const checkDevTools = setInterval(() => {
  //     if (window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100) {
  //       window.location.href = "about:blank"; // Redirect to a blank page
  //     }
  //   }, 1000);

  //   return () => clearInterval(checkDevTools);
  // }, []);

  //////////******ENDDDD */

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const fetchedIp = data.ip;
        setipAddress(data.ip?data?.ip:"152.58.176.22")
        sessionStorage.setItem("ipAddress", fetchedIp);
      } catch (error) {
        setipAddress("152.58.176.22")
        console.error("Error fetching the public IP address:", error);
      }
    };

    fetchIP();
    ReactGA.initialize("G-LYXTPD7R6D");
  }, []);


  useEffect(() => {
    if(ipAddress){
      fetch(`https://ipinfo.io/${ipAddress}?token=4e31838e3a3589`)
      .then((response) => response.json())
      .then((data) => {
        // setCountry("US");
        setCountry(data.country || "US"); // Extracts country code
      })
      .catch((error) => console.error("Error fetching country:", error));
    }
  }, [ipAddress]);


  useEffect(() => {
    const getCurrencyCode = async (countryCode) => {
      try {
        const response = await fetch(
          `https://restcountries.com/v3.1/alpha/${countryCode}`
        );
        const data = await response.json();

        if (data && data.length > 0 && data[0].currencies) {
          const currencyCode = Object.keys(data[0].currencies)[0];
          const currencySymbol = data[0].currencies[currencyCode].symbol;

          setcurrentCurrency({ code: currencyCode || "USD", symbol: currencySymbol || "$"});
        }
      } catch (error) {
        setcurrentCurrency({ code: "USD" , symbol: "$"});
        console.error("Error fetching currency:", error);
      }
    };

    if (country) {
      getCurrencyCode(country);
    }
  }, [country]);


  console.log("welcome",country)
  console.log("currentCurrency",currentCurrency)
  // console.log("setcurrecncy value",currentCurrencyValue)

  useEffect(() => {
    const fetchCurrencyValue = async () => {
      try {
        const res = await ApiCall("post", currencyChange.getCurrencyvalue, {
          currencyCode: currentCurrency?.code,
        });
        if (res?.success) {
          setcurrentCurrencyValue({
            symbol: currentCurrency?.symbol,
            value: res?.result?.toFixed(2),
          });
        } else {
          // Handle failure case
        }
      } catch (error) {
        
        console.error("Error fetching currency value:", error);
      }
    };

    fetchCurrencyValue();
  }, [currentCurrency]);

  return (
    <div>
      <Router>
        <Suspense fallback={<PrimaryMainLoader />}>
          <ScrollToTop />
          <Navbar
            country={country}
            currentCurrency={currentCurrency}
            setcurrentCurrency={setcurrentCurrency}
          />
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/hotel" element={<HotelHome />} />
            <Route path="/hotelslist" element={<HotelsList />} />
            <Route path="/hoteldetails" element={<HotelDetails />} />
            <Route path="/bookmyroom" element={<BookMyRoom />} />
            <Route
              path="/hotelbooksuccessfully"
              element={<HotelBookSuccessfully />}
            />
            <Route path="/cab" element={<CabHome />} />
            <Route path="/cabslist" element={<CabsList />} />
            <Route path="/cabbooking" element={<CabBooking />} />
            <Route
              path="/Home/Floading"
              element={<Flights currentCurrencyValue={currentCurrencyValue} />}
            />
            <Route
              path="/checkout"
              element={
                <Checkout
                country={country}
                  currentCurrency={currentCurrency}
                  currentCurrencyValue={currentCurrencyValue}
                />
              }
            />
            <Route
              path="/booksuccessfully"
              element={
                <BookSuccessfully currentCurrencyValue={currentCurrencyValue} />
              }
            />
            <Route path="/deals" element={<Deals />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/singleblog/:id" element={<SingleBlog />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              path="/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/faqs" element={<FAQ />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>

      {/* <ContactusButton /> */}

      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
      />
    </div>
  );
};

export default App;
