import axios from "axios";
import { ACTIVE_VERSION, BASE_URL } from "./base";
import { resolveErrorMessage } from "./service";
import { toast } from "react-toastify";

const API = axios.create({ baseURL: BASE_URL });

// API.interceptors.request.use((req) => {
//   if (sessionStorage.getItem("refreshTokenForTaskManager")) {
//     req.headers.token = sessionStorage.getItem("refreshTokenForTaskManager");
//   }
//   req.headers.version = ACTIVE_VERSION;
//   req.headers.platform = "dashboard";
//   return req;
// });

const ApiCall = async (type, route, data) => {
  try {
    let response;
    const apiFunctions = {
      post: API.post,
      get: API.get,
      patch: API.patch,
      delete: API.delete,
      put: API.put,
    };
    response = await apiFunctions[type](route, data);
    var success = true;
    if (response?.data) {
      return response?.data;
    }
  } catch (error) {
    success = false;
    // toast.error(
    //   `Something went wrong, ${resolveErrorMessage(error)}`
    // );
    console.error(`Something went wrong, ${resolveErrorMessage(error)}`);
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default ApiCall;
