let host = window.location.href;
let isProduction = host?.includes("bestairfares") ? true : false;
console.log(
  isProduction
    ? "🚨🚨🚨🚨🚨🚨LIVE🚨🚨🚨🚨🚨🚨"
    : "🚧🚧🚧🚧🚧DEVELOPMENT🚧🚧🚧🚧🚧🚧"
);

// ********* BASE URL ********
export const BASE_URL = isProduction
  ? "https://endpoint.bestairfares.com/api"
  : "https://badev.sociohood.com/api";

  

// ********* VERSION URL *******
// export const VERSION_URL = isProduction
//   ? "https://versionprod.sociohood.com/api"
//   : "https://version.sociohood.com/api";

// export const ACTIVE_VERSION = isProduction ? "1.0.Production" : "1.1";

// ********* AWS BUCKET NAME ********
// export const AWS_BUCKET_NAME = isProduction
//   ? "bizhood-task-manager-dashboard"
//   : "sociohood-development-bucket";

export const AWS_BUCKET_NAME = "ogule-website"