import React from "react";

const PrimaryMainLoader = () => {
  return (
    <div className="primary-main-loader">
      Loading
      <span></span>
    </div>
  );
};

export default PrimaryMainLoader;
