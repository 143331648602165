import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analyticsInstance } from "../GlobalFunction/BaseTracking";

const ScrollToTop = () => {
  const { pathname,search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  useEffect(() => {
    analyticsInstance().send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }, [pathname, search]);

  return null;
};

export default ScrollToTop;
