import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "./Assets/Styles/Style.css";
import "./Assets/Styles/Loaders.css";
import "./Assets/Styles/PrimaryMainLoader.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ReactDOM.render(
//   <I18nextProvider i18n={i18n}>
//     <App />
//   </I18nextProvider>,
//   document.getElementById('root')
// );