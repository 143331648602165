import React from "react";
import faqSvg from "../../Assets/svg/faqSvg.svg";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();

  const accordionItems = [
    {
      title: t("faq.question_1_title"),
      content: t("faq.question_1_content"),
    },
    {
      title: t("faq.question_2_title"),
      content: t("faq.question_2_content"),
    },
    {
      title: t("faq.question_3_title"),
      content: t("faq.question_3_content"),
    },
    {
      title: t("faq.question_4_title"),
      content: t("faq.question_4_content"),
    },
  ];

  return (
    <div className="custom-container py-3">
      <div className="d-flex flex-column gap-4">
        <h4 className="text-primary-theme fw-normal h3">
          {t("faq.faq_title")}
        </h4>
        <div className="d-flex flex-column-reverse flex-md-row g-4">
          <div className="col-md-6">
            <div className="accordion" id="accordionExample">
              {accordionItems.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`accordion-button ${
                        index === 0 ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`collapse${index}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{item.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center w-100 h-100">
              <img className="w-75" src={faqSvg} alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
